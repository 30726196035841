import { FloatingOverlay } from '@floating-ui/react'
import { CloseIcon } from '@toasttab/buffet-pui-icons'
import * as React from 'react'
import { CSSTransition } from 'react-transition-group'
import styles from './Drawer.module.css'

interface DrawerProps {
  header: React.ReactNode
  onClose: () => void
  footer?: React.ReactNode
  children: React.ReactNode
}

export const Drawer = ({ header, footer, onClose, children }: DrawerProps) => {
  React.useEffect(() => {
    const scrollWidth = window.innerWidth - document.body.clientWidth
    document.body.classList.add('drawer-open')
    document.body.style.margin = `0 ${scrollWidth}px 0 0`

    return () => {
      document.body.classList.remove('drawer-open')
      document.body.style.margin = ''
    }
  }, [])

  const handleClose = () => {
    onClose()
  }
  return (
    <FloatingOverlay
      className={styles.background}
      onClick={handleClose}
      lockScroll
      data-testid='drawer-overlay'
    >
      <CSSTransition in classNames={{ ...styles }} timeout={1000} appear>
        <div
          role='dialog'
          className={styles.drawer}
          onClick={(e) => e.stopPropagation()}
        >
          <div className='relative left-[90%] top-[12px]' onClick={handleClose}>
            <CloseIcon data-testid='drawer-close-button' />
          </div>
          <div className='px-6'>
            <div data-testid='drawer-header-content'>{header}</div>
          </div>

          <div className={styles.body}>{children}</div>
          {footer && <div className='text-center'>{footer}</div>}
        </div>
      </CSSTransition>
    </FloatingOverlay>
  )
}
