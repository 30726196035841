import { Formik } from 'formik'
import * as React from 'react'

import { SpiPaymentModule } from './SpiPaymentModule'
import { spiContactValidationSchema } from './StartTabForms.helpers'
import { SpiFormValues } from './StartTabForms.types'

export const SpiModuleWrapper = ({
  onSubmit
}: {
  onSubmit: (values: SpiFormValues) => void
}) => {
  return (
    <Formik
      initialValues={{
        spiPaymentMethod: null,
        firstName: '',
        lastName: '',
        phone: '',
        email: ''
      }}
      validationSchema={spiContactValidationSchema}
      onSubmit={onSubmit}
    >
      <SpiPaymentModule />
    </Formik>
  )
}
