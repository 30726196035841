import * as React from 'react'
import { useFlag } from '../../hooks/core/useFlag/useFlag'
import { LDFlags } from '../../launchdarkly/flags'
import { experimentStorage, getOrStoreVariant } from './utils'

export interface ExperimentDefinition {
  name: string
  flagName: LDFlags
  initializer: () => number
}

/**********************************************
 Experiment Name: Example
 Description: Example
 Variants:
 0: Control - No Treatment - 50%
 1: Treatment - 50%
 **********************************************/
export const XP_EXAMPLE: ExperimentDefinition = {
  name: 'example',
  flagName: 'example' as LDFlags,
  initializer: () => {
    return getOrStoreVariant(XP_EXAMPLE.name, [1, 1])
  }
}

export const experiments: { [key: string]: ExperimentDefinition } = {
  // [XP_EXAMPLE.name]: XP_EXAMPLE <--- add your experiment here to register
}

export const registerExperiments = () => [
  Object.values(experiments).forEach((experiment) => {
    const { initializer } = experiment
    initializer()
  })
]

export const useVariant = (experimentName: string) => {
  const { flagName } = experiments[experimentName]
  const variant = experimentStorage.getItem(experimentName)
  const experimentEnabled = useFlag(flagName)

  return experimentEnabled ? variant : null
}
