import { SpiData, spiData$ } from 'cornucopia-apis'
import * as React from 'react'

export const useSpiData = () => {
  const [spiData, setSpiData] = React.useState<SpiData>({
    selectedPaymentMethod: null,
    isValid: false
  })

  React.useEffect(() => {
    const subscription = spiData$.subscribe((config) => {
      setSpiData(config)
    })

    return () => subscription.unsubscribe()
  })

  return { spiData }
}
