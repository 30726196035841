import { useAuthContext } from '@local/cornucopia-subscriptions'
import { useSpiData } from '@local/cornucopia-subscriptions/src/hooks/useSpiData'
import {
  PasswordlessAuthUI,
  PasswordlessAuthUIVariant
} from '@toasttab/do-secundo-passwordless-authentication-ui'
import { Form, useFormikContext } from 'formik'
import * as React from 'react'
import { DisclaimerText } from '../DisclaimerText/DisclaimerText'
import { ContactInfo } from './ContactInfo'
import { SpiPaymentButton } from './SpiPaymentButton'
import { isContactInfoSame } from './StartTabForms.helpers'
import { SpiFormValues } from './StartTabForms.types'

export const SpiPaymentModule = () => {
  const { authContext, loading: authContextLoading } = useAuthContext()
  const { user, authenticated } = authContext || {}
  const { values, isSubmitting, isValid, setFieldValue, setValues } =
    useFormikContext<SpiFormValues>()

  const { spiData } = useSpiData()

  const { isValid: isSpiValid, selectedPaymentMethod } = spiData

  const shouldRenderContactInfo =
    !authenticated && selectedPaymentMethod === 'NEW_CARD'

  React.useEffect(() => {
    setFieldValue('spiPaymentMethod', selectedPaymentMethod)
  }, [selectedPaymentMethod, setFieldValue])

  React.useEffect(() => {
    if (!user) {
      return
    }

    if (!isContactInfoSame(user, values)) {
      setValues({
        ...values,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        email: user.email
      })
    }
  }, [setValues, user, values])

  const { firstName, lastName, email, phone } = values
  return (
    <Form>
      <div data-testid='spi-payment-module' className='mx-6 space-y-8'>
        {shouldRenderContactInfo && <ContactInfo />}
        <div>
          <div className='font-sans mb-6 font-semibold text-gray-75 text-corn-header-sm'>
            TAB PAYMENT
          </div>
          <div className='border border-solid rounded-lg px-4 '>
            <div className='z-[999999] w-full' id='spi-frame-anchor' />
          </div>
        </div>
        {authContextLoading ? null : (
          <div className='my-4'>
            <PasswordlessAuthUI
              initialFirstName={firstName}
              initialLastName={lastName}
              initialPhoneNumber={phone?.replace(/\D/g, '')}
              initialEmail={email}
              auth={authContext!}
              variant={PasswordlessAuthUIVariant.CHECKOUT_BOX}
            />
          </div>
        )}
        <SpiPaymentButton
          disabled={!isValid || !isSpiValid}
          submitting={isSubmitting}
        />
        <div className='mt-4 pb-8'>
          <DisclaimerText />
        </div>
      </div>
    </Form>
  )
}
