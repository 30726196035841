import { Button } from '@local/button'
import { useSpiData } from '@local/cornucopia-subscriptions/src/hooks/useSpiData'
import cx from 'classnames'
import * as React from 'react'
import styles from './PaymentForm/PaymentForm.module.css'

export const SpiPaymentButton = ({
  submitting,
  disabled
}: {
  submitting: boolean
  disabled: boolean
}) => {
  const { spiData } = useSpiData()
  const { selectedPaymentMethod } = spiData || {}

  if (selectedPaymentMethod === 'APPLE_PAY') {
    return (
      <button
        disabled={disabled}
        type='submit'
        className={cx(styles.applePay, styles.applePayPreauth)}
        data-testid='create-preauth-tab-submit-apple-pay-button'
      />
    )
  }

  return (
    <Button
      data-testid='create-preauth-tab-submit-credit-card-button'
      type='submit'
      disabled={disabled}
      loading={submitting}
    >
      Start tab & place order
    </Button>
  )
}
