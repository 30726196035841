import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { ReviewOrderTable } from '@local/review-order-table'
import { AppliedServiceCharge, order as orderFn } from 'cornucopia-apis'
import { ORDERING_ROUTES } from '../../app/OrderingRoutes'
import { Divider } from '../../components/Divider/Divider'
import { OrderButton } from '../../components/OrderButton'
import { PageHeader } from '../../components/PageHeader'
import { PageToolbar } from '../../components/PageToolbar'
import { BackToSafeLocationButton } from '../../components/PageToolbar/BackToSafeLocationButton/BackToSafeLocationButton'
import { Upsells } from '../../components/Upsells/Upsells'
import { getContactInfoInitialValues } from '../../utils/auth-utils/auth-utils'
import {
  calculateOrderSubtotal,
  getServiceChargesFromCart,
  useGetPartyMemberCart
} from '../../utils/cart-utils/helpers'
import { LoadingPage } from '../LoadingPage/LoadingPage'

export const AddToTabPage = ({
  setSkipRedirect
}: {
  setSkipRedirect: (value: boolean) => void
}) => {
  const { cart, loading } = useGetPartyMemberCart()

  const appliedServiceCharges = getServiceChargesFromCart(cart).filter(
    (appliedServiceCharge: AppliedServiceCharge) => {
      return appliedServiceCharge.chargeType !== 'FIXED'
    }
  )
  const [submitting, setSubmitting] = React.useState(false)
  const navigate = useNavigate()

  const subtotal = cart && calculateOrderSubtotal([cart], appliedServiceCharges)

  const contactInfo = getContactInfoInitialValues()

  const onOrder = async () => {
    setSubmitting(true)
    setSkipRedirect(true)

    orderFn({
      firstName: contactInfo.firstName,
      lastName: contactInfo.lastName,
      phone: contactInfo.phone,
      email: contactInfo?.email || 'dummy@email.com'
    }).subscribe((response) => {
      if (response.kind === 'OK') {
        navigate(ORDERING_ROUTES.CONFIRM, { replace: true })
      } else {
        setSkipRedirect(false)
        setSubmitting(false)
      }
    })
  }

  if (loading) {
    return <LoadingPage dataTestId='add-to-tab-loading' />
  }
  return (
    <div data-testid='add-tab-page'>
      <PageToolbar
        contactInfo={contactInfo}
        left={<BackToSafeLocationButton />}
      />
      <div className='mt-28'>
        <PageHeader
          header='Add to tab'
          subheader='Remember to close your tab before you leave.'
        />
      </div>
      <div className='my-8'>
        <Divider />
      </div>
      <ReviewOrderTable cart={cart} ignoreFixedCharges />
      <div className='pb-24'>
        <Upsells />
      </div>
      <div className='self-end'>
        <OrderButton
          subtotal={subtotal || 0}
          onClick={onOrder}
          submitting={submitting}
        />
      </div>
    </div>
  )
}
