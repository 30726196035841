import { Guid, JoinOrCreatePartyMode } from '@local/shared-types'
import { joinOrCreateParty } from 'cornucopia-apis'
import { Formik, FormikHelpers } from 'formik'
import * as React from 'react'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import {
  BottomContinueButton,
  ContactForm,
  ContactHeader,
  ContactInfoValues,
  getContactFormInitialValues,
  validationSchema
} from '../../components/Contact'
import {
  TransitionDirection,
  useTransitionContext
} from '../../components/TransitionProvider/TransitionContext'
import { useConditionalRedirect } from '../../hooks/core/redirects/useConditionalRedirect'

export const ContactInfoJoinGroupPage = () => {
  const initialValues = getContactFormInitialValues()
  const { setTransition } = useTransitionContext()
  const navigate = useNavigate()
  const location = useLocation()
  const { guid }: { guid: Guid } = location.state || {}

  useConditionalRedirect({ condition: !Boolean(guid) })

  const handleOnSubmit = (
    values: ContactInfoValues,
    helpers: FormikHelpers<ContactInfoValues>
  ) => {
    joinOrCreateParty({
      user: { name: values.fullName, phoneNumber: values.phone },
      partyGuid: guid,
      mode: JoinOrCreatePartyMode.JOIN
    }).subscribe((result) => {
      if (result.kind === 'OK') {
        setTransition(TransitionDirection.ForwardWithExit)
        navigate('/', { state: values })
      } else {
        helpers.setSubmitting(false)
      }
    })
  }

  return (
    <div data-testid='group-contact-info-page-join'>
      <ContactHeader headerText='Contact info needed to join a group' />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, formikBag) => handleOnSubmit(values, formikBag)}
        validationSchema={validationSchema}
      >
        <ContactForm submitButton={<BottomContinueButton />} />
      </Formik>
    </div>
  )
}
