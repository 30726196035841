import { useCurrentUser, useParty } from '@local/cornucopia-subscriptions'
import { ReviewGroupOrderTable } from '@local/review-order-table'
import { order as orderFn } from 'cornucopia-apis'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { ORDERING_ROUTES } from '../../app/OrderingRoutes'
import { Divider } from '../../components/Divider/Divider'
import { OrderButton } from '../../components/OrderButton'
import { PageHeader } from '../../components/PageHeader'
import { PageToolbar } from '../../components/PageToolbar'
import { BackToSafeLocationButton } from '../../components/PageToolbar/BackToSafeLocationButton/BackToSafeLocationButton'
import { Upsells } from '../../components/Upsells/Upsells'
import { getContactInfoInitialValues } from '../../utils/auth-utils/auth-utils'
import {
  calculateOrderSubtotal,
  getPartyMembers,
  getServiceChargesFromOrder
} from '../../utils/cart-utils/helpers'
import { LoadingPage } from '../LoadingPage/LoadingPage'

export const GroupAddToTabPage = ({
  setSkipRedirect
}: {
  setSkipRedirect: (value: boolean) => void
}) => {
  const { partyRefresh } = useParty()
  const { currentUser } = useCurrentUser()

  const [submitting, setSubmitting] = React.useState(false)

  const navigate = useNavigate()

  if (!partyRefresh || !currentUser) {
    return <LoadingPage dataTestId='group-add-tab-loading' />
  }

  const appliedServiceCharges = getServiceChargesFromOrder(
    partyRefresh.order,
    partyRefresh.party?.mainCheckGuid
  ).filter((appliedServiceCharge) => {
    return appliedServiceCharge.chargeType !== 'FIXED'
  })

  const subtotal = calculateOrderSubtotal(partyRefresh.carts)

  const partyMembers = getPartyMembers(partyRefresh)

  const headerText =
    partyMembers && partyMembers.length > 1 ? 'Add to group tab' : 'Add to tab'

  const contactInfo = getContactInfoInitialValues()

  const onOrder = async () => {
    setSubmitting(true)
    setSkipRedirect(true)

    orderFn({
      firstName: contactInfo.firstName,
      lastName: contactInfo.lastName,
      phone: contactInfo.phone,
      email: contactInfo?.email || 'dummy@email.com'
    }).subscribe((response) => {
      if (response.kind === 'OK') {
        navigate(ORDERING_ROUTES.CONFIRM, { replace: true })
      } else {
        setSkipRedirect(false)
        setSubmitting(false)
      }
    })
  }

  return (
    <div data-testid='group-add-tab-page'>
      <PageToolbar
        contactInfo={contactInfo}
        left={<BackToSafeLocationButton />}
      />
      <div className='mt-28'>
        <PageHeader
          header={headerText}
          subheader='Remember to close your tab before you leave.'
        />
      </div>
      <div className='my-8'>
        <Divider />
      </div>
      <div>
        <ReviewGroupOrderTable
          serviceCharges={appliedServiceCharges}
          currentUser={currentUser}
          users={partyRefresh.party?.members || []}
          carts={partyRefresh.carts}
        />
      </div>
      <div className='pb-24'>
        <Upsells />
      </div>
      <div className='self-end'>
        <OrderButton
          subtotal={subtotal}
          onClick={onOrder}
          submitting={submitting}
        />
      </div>
    </div>
  )
}
