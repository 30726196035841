import { ReviewOrderTable } from '@local/review-order-table'
import { preauth } from 'cornucopia-apis'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { ORDERING_ROUTES } from '../../app/OrderingRoutes'
import { Divider } from '../../components/Divider'
import { PageHeader } from '../../components/PageHeader'
import { PageToolbar } from '../../components/PageToolbar'
import { BackToSafeLocationButton } from '../../components/PageToolbar/BackToSafeLocationButton/BackToSafeLocationButton'
import { SpiModuleWrapper } from '../../components/StartTabForms/SpiModuleWrapper'
import { ContactFormValues } from '../../components/StartTabForms/StartTabForms.types'
import {
  TransitionDirection,
  useTransitionContext
} from '../../components/TransitionProvider/TransitionContext'
import { getContactInfoInitialValues } from '../../utils/auth-utils/auth-utils'
import { useGetPartyMemberCart } from '../../utils/cart-utils/helpers'
import { LoadingPage } from '../LoadingPage/LoadingPage'

export const IncAuthStartTabPage = ({
  setSkipRedirect
}: {
  setSkipRedirect: (value: boolean) => void
}) => {
  const navigate = useNavigate()
  const { loading, cart } = useGetPartyMemberCart()
  const { setTransition } = useTransitionContext()

  if (loading) {
    return <LoadingPage dataTestId='inc-auth-start-preauth-tab-loading' />
  }

  const contactInfo = getContactInfoInitialValues()

  const onSubmit = async (values: ContactFormValues) => {
    setTransition(TransitionDirection.ForwardWithExit)
    setSkipRedirect(true)
    await new Promise((resolve) => {
      preauth({ incAuth: true, ...values }).subscribe((res) => {
        if (res.kind === 'OK') {
          navigate(ORDERING_ROUTES.CONFIRM, { replace: true })
        } else {
          setSkipRedirect(false)
        }
        resolve(res)
      })
    })
  }

  return (
    <div data-testid='inc-auth-start-tab-page'>
      <PageToolbar
        left={<BackToSafeLocationButton />}
        contactInfo={contactInfo}
      />
      <div className='mt-28'>
        <PageHeader
          header='Start a tab'
          subheader="It's like a real tab. A temporary hold will be placed on your card until the tab is closed."
        />
        <div className='my-8'>
          <Divider />
        </div>
        <div>
          <ReviewOrderTable cart={cart} />
        </div>
        <div className='my-8'>
          <Divider />
        </div>
        <SpiModuleWrapper onSubmit={onSubmit} />
      </div>
    </div>
  )
}
