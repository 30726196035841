import { useCurrentUser, useParty } from '@local/cornucopia-subscriptions'
import { Loading } from '@toasttab/do-secundo-loading'
import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { ORDERING_ROUTES } from '../../../../src/app/OrderingRoutes'
import { CheckReviewOption } from '../../../../src/components/CheckReviewOption/CheckReviewOption'
import { useFlag } from '../../../../src/hooks/core/useFlag/useFlag'
import { LDFlags } from '../../../../src/launchdarkly/flags'
import { PaymentOptionsList } from './PaymentOptionsList'

export const PaymentOptionsWrapper = ({
  onComplete,
  open,
  isGroupOrder
}: {
  onComplete?(): void
  open: boolean
  isGroupOrder: boolean
}) => {
  const { partyRefresh, refetch } = useParty()
  const incAuthEnabled = useFlag(LDFlags.INCREMENTAL_AUTH)
  const { currentUser } = useCurrentUser()
  const location = useLocation()
  const replaceCurrentPage = location.pathname === ORDERING_ROUTES.CONFIRM

  const tabOwnerCloseoutOnly = Boolean(
    incAuthEnabled && partyRefresh?.incrementAuthData
  )

  const everyCheckActuallyClosed = partyRefresh?.order?.checks?.every(
    (check) =>
      check.paymentStatus === 'CLOSED' ||
      Boolean(
        check.paymentStatus === 'PAID' &&
          partyRefresh.incrementAuthData?.lastIncrement
      )
  )

  const isClosed = incAuthEnabled
    ? everyCheckActuallyClosed
    : partyRefresh?.order?.isClosed

  React.useEffect(() => {
    if (open && !partyRefresh && refetch) refetch()
  }, [refetch, partyRefresh, open])

  React.useEffect(() => {
    if (isClosed) {
      onComplete && onComplete()
    }
  }, [refetch, partyRefresh, onComplete, isClosed])

  if (!partyRefresh || !currentUser) {
    return (
      <div
        data-testid='payment-options-loading'
        className='flex items-center justify-center w-full h-full'
      >
        <Loading />
      </div>
    )
  }

  const tabOwner = partyRefresh.party.members.find(
    (member) => !!member.preauthCardGuid
  )
  const isCurrentUserTabOwner =
    tabOwner?.partyMemberGuid === currentUser.partyMemberGuid

  const currentUserHasSelections = currentUser.orderSelectionIds.length > 0

  return (
    <div data-testid='payment-options-group' className='pb-24'>
      <PaymentOptionsList
        isTabOwner={isCurrentUserTabOwner}
        replaceCurrentPage={replaceCurrentPage}
        onComplete={onComplete}
        currentUserHasSelections={currentUserHasSelections}
        tabOwnerCloseoutOnly={tabOwnerCloseoutOnly}
      />
      <CheckReviewOption
        isGroupOrder={isGroupOrder}
        replaceCurrentPage={replaceCurrentPage}
      />
    </div>
  )
}
