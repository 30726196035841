export enum PaymentMethods {
  APPLE_PAY = 'Apple Pay',
  CREDIT_CARD = 'Credit Card'
}

export interface PaymentFormValues {
  paymentMethod: PaymentMethods
  firstName: string
  lastName: string
  phone: string
  email: string
  encryptedCard: any
  saveCard: boolean
  selectedCard: string | null
}

export interface SpiFormValues {
  firstName: string
  lastName: string
  phone: string
  email: string
  spiPaymentMethod: string | null
}

export interface ContactFormValues {
  firstName: string
  lastName: string
  phone: IntlPhone | string
  email?: string
}

export interface IntlPhone {
  countryCode: string
  countryIsoCode: string
  nationalNumber: string
}
